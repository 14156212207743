import RestApi, { baseUrl } from '../config/api_config'

export default {
  computed: {
    hasAllDropdownLoaded () {
      return this.$store.state.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasAllDropdownLoaded: function (newValue) {
      if (!newValue) {
        this.loadCommonDropdown()
      }
    },
  },
  created () {
    // Loading common dropdowns
    const hasDropdownLoaded = this.$store.state.commonObj.hasDropdownLoaded
    if (!hasDropdownLoaded || window.performance) {
      this.loadCommonDropdown()
    }
  },
  methods: {
    loadCommonDropdown () {
      RestApi.getData(baseUrl, 'api/common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('mutateDropdown', {
            hasDropdownLoaded: true,
            withdrawCategoryList: response.data.withdrawCategoryList,
            quizCategoryList: response.data.quizCategoryList,
            articleCategoryList: response.data.articleCategoryList,
            withdrawMethodList: response.data.withdrawMethodList,
            adsNetWorkList: response.data.adsNetWorkList,
            vpnList: response.data.vpnList,
            permissionList: response.data.permissionList,
            permissions: response.data.permissions,
            roleList: response.data.roleList,
          })
        }
      })
    }
  }
}
